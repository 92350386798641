import { useMutation } from "@apollo/react-hooks"
import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import { Button } from "./Etc"
import { theme } from "../constants"
import { Authentication } from "../utils/Authentication"
import { GraphQL } from "../utils/GraphQL"
import { Gatsby } from "../utils/Gatsby"

export const CALENDLY_URL = "https://calendly.com/internetsanitizer"

const TextInput = styled.input`
  padding: 5px;
  &:placeholder-shown {
    font-size: 18px;
  }
  transition: font-size 0.5s;
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(100, 100, 100);
  background-color: ${theme.background};
  color: rgb(200, 200, 200);

  &:focus {
    color: white;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
`

const TextArea = styled.textarea`
  padding: 5px;
  &:placeholder-shown {
    font-size: 18px;
  }
  font-family: "Open Sans", sans-serif;
  transition: font-size 0.5s;
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(100, 100, 100);
  background-color: ${theme.background};
  color: rgb(200, 200, 200);

  &:focus {
    color: white;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
`

const Form = styled.form``

const Label = styled.label`
  padding: 10px 0;
  color: rgb(100, 100, 100);
  width: 100%;
  vertical-align: top;
  text-transform: uppercase;
  display: inline-block;

  &:hover,
  &:active,
  &:focus {
    color: white;
  }
`

const Submit = styled.div`
  display: flex;

  flex-flow: row nowrap;

  @media (max-width: ${theme.width.tablet}) {
    & > div {
      display: none;
    }
  }
`

const RadioButtonGroup = styled.div`
  color: rgb(100, 100, 100);
  & label {
    border-radius: 5px;
    border: 1px solid rgb(100, 100, 100);
    padding: 5px 10px;
    margin: 2px 5px;
  }
  & label:first-child {
    margin-left: 0;
  }
  & label:last-child {
    margin-right: 0;
  }
  & label:hover {
    color: white;
  }
`

enum Type {
  ORDER = "ORDER",
  SUPPORT = "SUPPORT",
  GENERAL_INQUIRY = "GENERAL_INQUIRY",
}

export const OrderForm = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="white"
      >
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.445 17.827c-3.684 1.684-9.401-9.43-5.8-11.308l1.053-.519 1.746 3.409-1.042.513c-1.095.587 1.185 5.04 2.305 4.497l1.032-.505 1.76 3.397-1.054.516z" />
      </svg>{" "}
      To schedule a phone call, click this link:{" "}
      <a href={CALENDLY_URL}>{CALENDLY_URL}</a>.
      <br />
      <br />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="white"
      >
        <path d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z" />
      </svg>{" "}
      Alternatively, feel free to email us:{" "}
      <a href="mailto:hello@internetsanitizer.com&subject=InternetSanitizer.com%20Contact%20">
        hello@internetsanitizer.com
      </a>
      .
    </>
  )

  // const [country, setCountry] = useState<string>('Canada')
  // const [city, setCity] = useState<string>('')
  // const [province, setProvince] = useState<string>('Ontario')
  // const [description, setDescription] = useState<string>('')
  // const [email, setEmail] = useState<string>('')
  // const [name, setName] = useState<string>('')
  // const [submitted, setSubmitted] = useState<boolean>(false)
  // const [submit, { data }] = useMutation(GraphQL.Support.mutations.createSupportTicket)
  // const [type, setType] = useState<Type>((new URLSearchParams(Gatsby.isRuntime() ? location.search : '').get('type')) as Type || Type.ORDER)

  // const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //     e.preventDefault()

  //     submit({ variables: { country, city, province, name, email, type, description }})
  //     setCountry('Canada')
  //     setCity('')
  //     setProvince('Ontario')
  //     setEmail('')
  //     setName('')
  //     setSubmitted(true)
  //     setType(Type.ORDER)
  //     setDescription('')
  // }

  // return <>
  //     {!submitted && <Form onSubmit={onSubmit}>
  //         {/* {Authentication.isLoggedIn() ? (
  //             <div>Note: You are logged in and will get priority service.</div>
  //         ) : (
  //             <div>Note: You are not logged in so it may take longer to gather information specific to your order.</div>
  //         )} */}
  //         <div style={{display: 'flex'}}>
  //             <div style={{flex: 1}}><Label>Name <TextInput name="Name" value={name} onChange={e => setName(e.target.value)} /></Label></div>
  //             <div style={{flex: 1}}><Label>Email <TextInput name="Email" value={email} onChange={e => setEmail(e.target.value)} /></Label></div>
  //         </div>
  //         <div style={{display: 'flex'}}>
  //             <div style={{flex: 1}}><Label>Country <TextInput name="Country" value={country} onChange={e => setCountry(e.target.value)} /></Label></div>
  //             <div style={{flex: 1}}><Label>Province <TextInput name="Province" value={province} onChange={e => setProvince(e.target.value)} /></Label></div>
  //             <div style={{flex: 1}}><Label>City <TextInput name="City" value={city} onChange={e => setCity(e.target.value)} /></Label></div>
  //         </div>
  //         <Label>Type</Label>
  //         <RadioButtonGroup>
  //             <label><input name="type" onChange={e => e.target.checked && setType(Type.ORDER)} checked={type === Type.ORDER} type="radio" /> Order</label>
  //             <label><input name="type" onChange={e => e.target.checked && setType(Type.SUPPORT)} checked={type === Type.SUPPORT} type="radio" /> Support</label>
  //             <label><input name="type" onChange={e => e.target.checked && setType(Type.GENERAL_INQUIRY)} checked={type === Type.GENERAL_INQUIRY} type="radio" /> General Inquiry</label>
  //         </RadioButtonGroup>
  //         <div style={{display: 'flex'}}>
  //             <div style={{flex: 1}}><Label>Details <TextArea rows={7} placeholder="Hello Internet Sanitizer team, ..." value={description} onChange={e => setDescription(e.target.value)}/></Label></div>
  //         </div>
  //         <Submit>
  //             <div style={{flex: 2}}></div>
  //             <Button style={{flex: 1}}>Submit {Authentication.isLoggedIn() ? `as ${Authentication.context().name}` : ''}</Button>
  //         </Submit>
  //     </Form>}
  //     {submitted && <div style={{color: 'limegreen'}}>
  //         Your order has been submitted! We'll contact to you with more details as soon as possible!
  //     </div>}
  // </>
}
