// polyfills
require("es6-promise").polyfill()
require("isomorphic-fetch")

import React, { useMemo } from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { Authentication } from "./utils/Authentication"
import ApolloClient from "apollo-boost"
import { createGlobalStyle } from "styled-components"

interface Props {
  element: React.ReactNode
}

const client = new ApolloClient({
  uri: "http://localhost:3000",
  headers: Authentication.getCSRFToken(),
})

const GlobalStyle = createGlobalStyle`
    @media(max-width: 600px) {
        html, body {
            font-size: 24px;
        }
    }
`

export const App = (props: { children: React.ReactNode }) => {
  return (
    <>
      <GlobalStyle />
      {props.children}
    </>
  )
}

export const RootWrapper = (props: Props) => {
  return (
    <ApolloProvider client={client}>
      <App>{props.element}</App>
    </ApolloProvider>
  )
}
