import React from "react"
import styled from "styled-components"

interface Props {
  color: string
  height: number
  position: 'topLeft'
          | 'topRight'
          | 'bottomLeft'
          | 'bottomRight'
}

const TopLeftTriangle = styled.div<Props>`
  border-bottom: ${p => p.height}px solid ${p => p.color};
  border-right: 100vw solid transparent;
  height: 0;
  width: 0;
  position: absolute;
`

const TopRightTriangle = styled.div<Props>`
  border-bottom: ${p => p.height}px solid ${p => p.color};
  border-left: 100vw solid transparent;
  height: 0;
  width: 0;
  position: absolute;
`

const BottomRightTriangle = styled.div<Props>`
  border-top: ${p => p.height}px solid ${p => p.color};
  border-left: 100vw solid transparent;
  height: 0;
  width: 0;
  position: absolute;
`

const BottomLeftTriangle = styled.div<Props>`
  border-top: ${p => p.height}px solid ${p => p.color};
  border-right: 100vw solid transparent;
  height: 0;
  width: 0;
  position: absolute;
`

const DividerWrapper = styled.div<Props>`
  display: block;
  position: ${p => (p.position === 'topLeft' || p.position === 'topRight') ? 'relative' : 'absolute'};
  height: ${p => (p.position === 'topLeft' || p.position === 'topRight') ? 0 : p.height + 'px'};
  width: 100%;
  padding-bottom: ${p => (p.position === 'topLeft' || p.position === 'topRight') ? p.height : 0}px;
  margin-top:    -${p => (p.position === 'topLeft' || p.position === 'topRight') ? p.height : 0}px;
  overflow: hidden;
`

export const Divider = (props: Props) => {
  return (
    <DividerWrapper {...props}>
      {props.position === 'topLeft' && <TopLeftTriangle {...props} />}
      {props.position === 'topRight' && <TopRightTriangle {...props} />}
      {props.position === 'bottomRight' && <BottomRightTriangle {...props} />}
      {props.position === 'bottomLeft' && <BottomLeftTriangle {...props} />}
    </DividerWrapper>
  )
}
