interface Theme {
  secondary: string
  secondaryText: string,
  accent: string
  darkAccent: string

  background: string,

  width: {
    tablet: string
  }
}

export const theme: Theme = {
  background: '#222',
  secondaryText: 'black',
  secondary: 'white',
  // secondary: '#ff7676',
  accent: '#34b4ff',
  darkAccent: '#1F6C99',
  // secondary: '#34FFD3' // 54, 255, 208 #34FFD3

  width: {
    tablet: '960px'
  }
}