import { useMutation } from "@apollo/react-hooks"
import React, { useState } from "react"
import styled from "styled-components"

import { Button } from "../components/Etc"
import { theme } from "../constants"
import { Authentication } from "../utils/Authentication"
import { GraphQL } from "../utils/GraphQL"

import { OrderForm } from "./OrderForm"

const TextInput = styled.input`
  padding: 5px;
  &:placeholder-shown {
    font-size: 18px;
  }
  transition: font-size 0.5s;
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(100, 100, 100);
  background-color: ${theme.background};
  color: rgb(200, 200, 200);

  &:focus {
    color: white;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
`

const TextArea = styled.textarea`
  padding: 5px;
  &:placeholder-shown {
    font-size: 18px;
  }
  font-family: "Open Sans", sans-serif;
  transition: font-size 0.5s;
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(100, 100, 100);
  background-color: ${theme.background};
  color: rgb(200, 200, 200);

  &:focus {
    color: white;
    border-bottom: 1px solid rgb(180, 180, 180);
  }
`

const Form = styled.form`
  & label {
    padding: 10px 0;
    color: rgb(100, 100, 100);
    width: 100%;
    vertical-align: top;
    text-transform: uppercase;
    display: inline-block;
  }

  & label:hover,
  & label:active,
  & label:focus {
    color: white;
  }
`

const Submit = styled.div`
  display: flex;

  flex-flow: row nowrap;

  @media (max-width: ${theme.width.tablet}) {
    & > div {
      display: none;
    }
  }
`

interface SubmitFormData {
  title: string
  description: string
}

export const SupportForm = () => {
  return <OrderForm />

  // const [title, setTitle] = useState<string>('')
  // const [description, setDescription] = useState<string>('')
  // const [submitted, setSubmitted] = useState<boolean>(false)
  // const [submit, { data }] = useMutation(GraphQL.Support.mutations.createSupportTicket)

  // const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //     submit({ variables: {title, description }})
  //     setTitle('')
  //     setDescription('')
  //     setSubmitted(true)
  // }

  // return <>
  //     {Authentication.isLoggedIn() ? (
  //         <div>Note: You are logged in and will get priority service.</div>
  //     ) : (
  //         <div>Note: You are not logged in so it may take longer to gather information specific to your order/account.</div>
  //     )}
  //     <Form onSubmit={onSubmit}>
  //         <div><label>Subject <TextInput placeholder="My device is not working..." name="title" value={title} onChange={e => setTitle(e.target.value)} /></label></div>
  //         <div><label>Details <TextArea rows={7} placeholder="I've noticed that..." value={description} onChange={e => setDescription(e.target.value)}/></label></div>
  //         <Submit>
  //             <div style={{flex: 2}}></div>
  //             <Button style={{flex: 1}}>Submit {Authentication.isLoggedIn() ? `as ${Authentication.context().name}` : 'anonymously'}</Button>
  //         </Submit>
  //     </Form>
  //     {submitted && <div style={{color: 'limegreen'}}>
  //         Support ticket created! We'll get back to you as soon as possible!
  //     </div>}
  // </>
}
