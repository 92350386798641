import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import LogoWide from '../components/LogoWide';
import { theme } from '../constants';
import { Authentication } from '../utils/Authentication';
import { Button } from './Etc'
import { Gatsby } from '../utils/Gatsby';

const Wrapper = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 auto;
  max-width: 960px;
  margin-top: 15px;
  padding: 0 1.5em;
  width: 100%;

  @media (max-width: ${theme.width.tablet}) {
    flex-flow: column nowrap;
  }
` 

const NavLink = styled(Link)`
  margin: 0 0 0 18px;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
    color: ${theme.accent}
  }
  @media (max-width: ${theme.width.tablet}) {
    padding: 10px 20px
  }
`

const AnchorLink = styled.a`
  margin: 0 0 0 18px;
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: underline;
    color: ${theme.accent}
  }
`

const Header = () => {
  const meta = useStaticQuery(graphql`
    query {
      site{
        siteMetadata{
          apiUrl
        }
      }
    }
  `)

  const { apiUrl } = meta.site.siteMetadata
  global.apiUrl = apiUrl
  const redirectURI = Gatsby.isRuntime() ? window.location.origin : 'http://localhost:8000'

  if (Authentication.hasLoggedIn()) {
    // do something?
  } else if (Authentication.hasLoggedOut()) {
    // do something?
  }
  
  return (
    <Wrapper>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
          width: '200px'
        }}
      >
        <LogoWide/>
      </Link>
      <div style={{flex: 1}} />
      <NavLink to="/device">Device</NavLink>
      <NavLink to="/pricing">Pricing</NavLink>
      {/* <NavLink to="/support">Support</NavLink> */}
      <NavLink to="/order"><Button>Order/Contact</Button></NavLink>
      {Authentication.isLoggedIn() ? (
        <AnchorLink href={`${apiUrl}/auth/oidc/logout?redirect_uri=${redirectURI}?logout=true`}>Logout</AnchorLink>
      ) : (
        <AnchorLink href={`${apiUrl}/auth/oidc/login?redirect_uri=${redirectURI}?login=true`}>Login</AnchorLink>
      )}
    </Wrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
