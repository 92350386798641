import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { theme } from '../constants';
import { Slide, Title, Button } from './Etc';
import { Home } from './images/Home'
import { School } from './images/School'
import { Business } from './images/Business'

const LineItem = styled.div<{first?: boolean}>`
  ${p => p.first ? `
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: bolder;
    font-size: 24px;
  ` : `
    height: 40px;
    line-height: 40px;
  `}
`

const Offer = styled.div`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
`

const OfferList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 70px;

  & ${Offer} {
    border-right: 1px dashed rgba(255, 255, 255, 0.2);
  }

  & ${Offer}:last-child {
    border: none;
  }

  & ${Offer}:first-child {
    font-weight: bolder;
    flex: unset;
    width: 200px;
    background: none;

    ${LineItem} {
      background: none;
    }
  }

  @media (max-width: ${theme.width.tablet}) {
    flex-flow: column nowrap;

    & ${Offer} ${LineItem} {
        border-right: none;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    }
  }
`

const OfferImage = styled.div`
  margin: 0 auto;
  text-align: center;
  height: 150px;
  width: 75px;
`

export const Pricing = () => {
  return (
    <Slide>
      <Title>No contracts, first month free.</Title>
      <OfferList>
        <Offer>
          <OfferImage>&nbsp;</OfferImage>
          <LineItem first>&nbsp;</LineItem>
          {/* <LineItem>Throughput<a href="#warning">*</a></LineItem> */}
          <LineItem>Recommended for</LineItem>
          <LineItem>Installation Fee</LineItem>
          <LineItem>&nbsp;</LineItem>
        </Offer>
        <Offer>
          <OfferImage><Home/></OfferImage>
          <LineItem first>Personal</LineItem>
          {/* <LineItem>1 - 10 Mbps</LineItem> */}
          <LineItem>0 – 10 devices</LineItem>
          <LineItem>Waived</LineItem>
          <LineItem>
            <Link to="/order"><Button>Order $12/mo</Button></Link>
          </LineItem>
        </Offer>
        <Offer>
          <OfferImage><School/></OfferImage>
          <LineItem first>Education</LineItem>
          <LineItem>-</LineItem>
          <LineItem>-</LineItem>
          <LineItem>
            <Link to="/order"><Button>Contact Us</Button></Link>
          </LineItem>
        </Offer>
        {<Offer>
          <OfferImage><Business/></OfferImage>
          <LineItem first>Business</LineItem>
          <LineItem>-</LineItem>
          <LineItem>-</LineItem>
          <LineItem>
            <Link to="/order"><Button>Contact us</Button></Link>
          </LineItem>
        </Offer>}
      </OfferList>
      {/* <br/>
      <br/>
      <br/>
      <br/>
      <br/> */}
      {/* <small id="warning">* Internet service is not provided and you will require an internet connection from an upstream ISP. Moreover, this throughput is an approximation and may vary depending on environmental and network conditions.</small> */}
    </Slide>
  )
}
