import React from "react"
import { theme } from "../constants"
import styled from "styled-components"
import Layout from "./layout"
import { Divider } from "./Divider"

interface Props {
  children: React.ReactNode
}

export const Table = styled.table`
  width: 100%;

  & td {
    text-align: center;
    border-bottom: 1px dotted white;
  }

  & td[data-green="true"] {
    background-color: green;
  }

  & td[data-yellow="true"] {
    color: gray;
    background-color: yellow;
  }

  & td[data-red="true"] {
    background-color: red;
  }
`

export const Data = styled.td<{
  green?: boolean
  yellow?: boolean
  red?: boolean
}>`
  border: 1px solid blue;
  ${p => p.green && "background-color: green;"}
  ${p => p.yellow && "background-color: yellow;"}
  ${p => p.red && "background-color: red;"}
`

export const Content = styled.main`
  margin: 0 auto;
  padding: 20px;
  max-width: ${theme.width.tablet};
`

export const Title = styled.h1`
  margin: 0;
  padding: 10px 20px 0 20px;
`

export const PageLayout = (title: string) => (props: Props) => {
  return (
    <Layout>
      <div style={{ marginTop: "45px" }} />
      <Divider height={25} position="topRight" color={theme.secondary} />
      <div style={{ background: "white", color: "black" }}>
        <Content>
          <Title>{title}</Title>
        </Content>
      </div>
      <Divider height={25} position="bottomLeft" color={theme.secondary} />
      <div style={{ marginBottom: "45px" }} />

      {props.children}
    </Layout>
  )
}
