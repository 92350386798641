import React from 'react';
import styled from 'styled-components';

import { theme } from '../constants';
import MapleLeaf from './images/MapleLeaf';

const FooterWrapper = styled.div`
  margin: 0 auto;
  max-width: ${theme.width.tablet};
  width: 100%;
  margin-top: 45px;
  font-size: 12px;
`

const List = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row-nowrap;

  @media (max-width: ${theme.width.tablet}) {
    flex-flow: column nowrap;
  }
`

const Item = styled.div<{flex: number}>`
  flex: ${p => p.flex};
  padding: 15px;

  & > * {
    display: block;
    margin-bottom: 10px;
  }
`

export default () => {
  return (
    <FooterWrapper>
      <List>
        <Item flex={1}>
          We care about your privacy and do not use third party services to track you.
          <br/><br/><a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </Item>
        <Item flex={1}>
        </Item>
        <Item style={{textAlign: 'right'}} flex={1}>
          <div style={{margin: '0 0 0 auto', width: '45px'}}><MapleLeaf /></div>
          100% Canadian
        </Item>
      </List>

    </FooterWrapper>
  )
}