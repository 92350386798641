import React from 'react'
import styled from 'styled-components'

const LoginFormWrapper = styled.form`
  display: flex;
  flex-flow: column nowrap;

  & input {
    padding: 5px;
    margin: 0;
    font-size: 18px;
  }
`

interface Props {

}

export default (props: Props) => {
  return (
    <LoginFormWrapper>
      <input name="username" placeholder="username" />
      <input name="password" placeholder="password" />
      <button>Login</button>
    </LoginFormWrapper>
  )
}


