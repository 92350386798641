/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./Footer"
import styled, { createGlobalStyle } from 'styled-components'
import { theme } from "../constants"

const PageWrapper = styled.div`
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding-top: 0;
  display: flex;
  flex-flow: column nowrap;
`

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    color: white;
    background-color: ${theme.background};
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${theme.accent};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Layout = ({ children }) => {
  return (
    <PageWrapper>
      <GlobalStyle />
      <Header />
      <main style={{flex: 1}}>{children}</main>
      <Footer />
    </PageWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
