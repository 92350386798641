import { Role } from '../../../backend/types/public';
import { Gatsby } from './Gatsby';

const ID_KEY = 'user-id'
const NAME_KEY = 'user-name'
const CSRF_TOKEN_KEY = 'user-csrfToken'
const ROLES_KEY = 'user-roles'

interface AuthContext {
    id: string
    name: string
    csrfToken: string
    roles: Role[]
}

export class Authentication {
    public static hasLoggedIn(): boolean {
        if (!Gatsby.isRuntime()) return false
        
        const queryParameters = new URLSearchParams(window.location.search) // query parameters
        const login = queryParameters.get('login')

        if (login) {
            const id = queryParameters.get('id')
            const name = queryParameters.get('name')
            const token = queryParameters.get('csrfToken')
            const roles = queryParameters.getAll('roles')
            
            this.loginAs(id, name, token, roles as unknown as Role[])
        }

        return !!login
    }

    private static loginAs(id: string, name: string, csrfToken: string, roles: Role[]) {
        localStorage.setItem(ID_KEY, id)
        localStorage.setItem(NAME_KEY, name)
        localStorage.setItem(CSRF_TOKEN_KEY, csrfToken)
        localStorage.setItem(ROLES_KEY, JSON.stringify(roles))
    }

    public static hasLoggedOut(): boolean {
        if (!Gatsby.isRuntime()) return false

        const queryParameters = new URLSearchParams(window.location.search) // query parameters
        const logout = queryParameters.get('logout')

        if (logout) {
            this.logout()
        }


        return !!logout
    }

    private static logout(): void {
        localStorage.removeItem(ID_KEY)
        localStorage.removeItem(NAME_KEY)
        localStorage.removeItem(CSRF_TOKEN_KEY)
        localStorage.removeItem(ROLES_KEY)
    }

    public static isLoggedIn(): boolean {
        if (!Gatsby.isRuntime()) return false
        
        return !!this.getCSRFToken()
    }

    public static context(): AuthContext | null {
        if (!Gatsby.isRuntime()) return null

        const id: string = localStorage.getItem(ID_KEY)
        const name: string = localStorage.getItem(NAME_KEY)
        const csrfToken: string = localStorage.getItem(CSRF_TOKEN_KEY)
        console.log('localStorage.getItem(ROLES_KEY)', localStorage.getItem(ROLES_KEY))
        const roles: Role[] = JSON.parse(localStorage.getItem(ROLES_KEY) || 'null')

        if (csrfToken) {
            // The CSRFToken is really the only thing needed for a valid session on the backend.

            return {
                id,
                name,
                csrfToken,
                roles
            }
        }

        return null
    }

    public static getCSRFToken(): string | null {
        if (!Gatsby.isRuntime()) return null
        
        const token = localStorage.getItem(CSRF_TOKEN_KEY)

        if (token) {
            return token
        }
        
        return null
    }
}
