import React from 'react'
import styled from 'styled-components';
import { theme } from '../constants';

export const Slide = styled.div`
  max-width: ${theme.width.tablet};
  margin: 45px auto 0;
`

export const Title = styled.div`
  text-align: center;
  font-size: 60px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  margin: 0 0 60px 0;
`

export const Button = styled.button`
  height: 40px;
  width: 100%;
  font-weight: 600;
  background: ${theme.accent};
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 5px;
  margin: 0;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  user-select: none;
  margin-top: 5px;

  &:hover {
    border-color: gray;
    cursor: pointer;
    border-bottom: 5px solid ${theme.darkAccent};
    height: 45px;
    margin-top: 0;
  }

  &:active {
    height: 40px;
    margin-top: 5px;
    border-bottom: 0;
  }
`