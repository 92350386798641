import { PageLayout, Content } from "../../../../src/components/PageLayout.tsx";
import LoginForm from "../../../../src/components/LoginForm.tsx";
import { OrderForm } from "../../../../src/components/OrderForm.tsx";
import * as React from 'react';
export default {
  PageLayout,
  Content,
  LoginForm,
  OrderForm,
  React
};