import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LogoWide = () => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fluid: {originalName: {eq: "logo-wide-white.png"}}) {
        id
        resize(width: 200) {
          src
        }
      }
    }
  `)

  return (
    <img src={data.imageSharp.resize.src} />
  )
}

export default LogoWide
