// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-device-mdx": () => import("./../../../src/pages/device.mdx" /* webpackChunkName: "component---src-pages-device-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-mdx": () => import("./../../../src/pages/login.mdx" /* webpackChunkName: "component---src-pages-login-mdx" */),
  "component---src-pages-order-mdx": () => import("./../../../src/pages/order.mdx" /* webpackChunkName: "component---src-pages-order-mdx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pricing-mdx": () => import("./../../../src/pages/pricing.mdx" /* webpackChunkName: "component---src-pages-pricing-mdx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-support-matrix-mdx": () => import("./../../../src/pages/SupportMatrix.mdx" /* webpackChunkName: "component---src-pages-support-matrix-mdx" */),
  "component---src-pages-support-mdx": () => import("./../../../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../../../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */)
}

